const captureVTO = async data => {
	if (!data.dataUrl) return;

	const blob = await (await fetch(data.dataUrl)).blob();
	const file = new File([blob], 'tryon.png', {
		type: blob.type,
		lastModified: new Date().getTime(),
	});
	const dataToShare = { files: [file] };
	if (navigator.canShare && navigator.canShare(dataToShare)) {
		// Share Image using Native Features
		try {
			await navigator.share(dataToShare);
		} catch (error) {
			console.error(error);
		}
	} else {
		// Auto-Download Image
		const a = document.createElement('a');
		a.href = data.dataUrl;
		a.download = 'tryon.png';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}
};
const fmKey = process.env.NEXT_PUBLIC_FITMIX_API_KEY;
const fitmixStyles = `
						.watermarkContainer {
							display: none;
						}

						span.fmixIcon.tryPhotoIcon {
							background: transparent url(//cdn.shopify.com/s/files/1/1147/9910/t/293/assets/upload.svg?v=2645473126251685866) no-repeat center center;
						}

						span.fmixIcon.relaunchDetectionButtonIcon {
							background: transparent url(https://cdn.shopify.com/s/files/1/1147/9910/files/refresh.svg.svg?v=1645026064) no-repeat center center;
							display: none;
						}

						span.fmixIcon.relaunchDetectionButtonIcon:hover {
							background: rgba(0, 0, 0, 0.32);
						}

						span.fmixIcon.relaunchDetectionButtonIcon:focus {
							background: rgba(0, 0, 0, 0.64);
						}

						vto-advanced-live-footer-left-action,
						vto-advanced-live-footer-right-action {
							display: none;
						}

						span.fmixIcon {
							width: 100px;
							height: 50px;
						}

						.loadAnimContainer {
							left: 10px;
							right: unset;
							top: 10px;
							bottom: unset;
						}
`;

export { captureVTO, fmKey, fitmixStyles };
